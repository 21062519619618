/* -------------------------------------------------------------------------- */
/*                                  Override                                  */
/* -------------------------------------------------------------------------- */
.dark {
  @each $color, $value in $theme-all-colors {
    .dark__bg-#{$color} {
      background-color: $value !important;
    }
    .dark__text-#{$color} {
      color: $value !important;
    }
  }

  .bg-light {
    --#{$variable-prefix}bg-opacity: 0.25;
  }

  ::selection {
    background-color: $warning;
  }
}